<script setup lang="ts">
import CatalogReferenceCarousel from '~/components/ui/CatalogReferenceCarousel.vue';
import HeroIcon from '~/components/ui/HeroIcon.vue';
import PageCard from '~/components/ui/PageCard.vue';
import PageSection from '~/components/ui/PageSection.vue';
import { useTexts } from '~/utils/client/useTexts';

useHead({
    title: 'Úvod',
});

const useText = await useTexts();

const tiles = [
    {
        icon: useText('tile1Icon'),
        title: useText('tile1Heading'),
        to: useText('tile1Link'),
        text: useText('tile1Text'),
    },
    {
        icon: useText('tile2Icon'),
        title: useText('tile2Heading'),
        to: useText('tile2Link'),
        text: useText('tile2Text'),
    },
    {
        icon: useText('tile3Icon'),
        title: useText('tile3Heading'),
        to: useText('tile3Link'),
        text: useText('tile3Text'),
    },
];
</script>

<template>
    <div>
        <PageSection id="intro" class="py-40 lg:!py-40">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <h2
                class="mb-5 text-lg md:text-xl lg:text-3xl text-primary animate-on-scroll resize-down move-up"
                v-html="useText('subheading')"
            ></h2>
            <h1
                class="text-accent cursor-default text-4xl md:text-6xl lg:text-8xl z-20 hover:scale-110 animate-on-scroll move-up resize-down"
            >
                {{ useText('heading') }}
            </h1>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <h2
                class="mt-20 md:text-ml lg:text-xl text-base-content animate-on-scroll resize-down move-up"
                v-html="useText('subheading2')"
            ></h2>
            <a
                href="#about"
                class="btn btn-lg btn-circle absolute bottom-10 animate-bounce bg-transparent shadow-none border-none"
            >
                <img
                    src="/icon.png"
                    class="rotate-90"
                    alt="Scroll down"
                    width="500"
                    height="500"
                />
            </a>
        </PageSection>
        <PageSection
            id="about"
            class="bg-neutral from-neutral to-neutral-focus text-neutral-content bg-gradient-to-r z-20 !py-20"
        >
            <div
                class="h-full w-full flex flex-col justify-start items-center"
            >
                <div class="text-center">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div class="about-content" v-html="useText('catchphrase')"></div>
                    <a href="#tiles" class="btn hover:scale-110 normal-case text-lg md:text-xl btn-lg mt-10">
                        <h2>{{ useText('catchphraseButton') }}</h2>
                    </a><br />
                    <NuxtLink
                        :to="useText('ctaLink2')"
                        class="btn hover:scale-110 normal-case text-md md:text-xl btn-lg border-2 btn-ghost mt-3"
                    >
                        <h2>{{ useText('cta2') }}</h2>
                    </NuxtLink>
                </div>
            </div>
        </PageSection>
        <PageSection id="tiles" class="!pt-28 !pb-0">
            <h2
                class="text-accent relative -left-7 cursor-default text-2xl md:text-4xl lg:text-6xl z-20 animate-on-scroll move-right mb-28"
            >
                {{ useText('tileHeading') }}
            </h2>
            <div class="flex flex-col lg:flex-row gap-5 text-left mb-36">
                <NuxtLink
                    v-for="({icon, title, text, to}, img) in tiles"
                    :key="img"
                    :to="to"
                    class="w-full lg:w-1/3"
                >
                    <PageCard
                        class="z-20 hover:translate-y-1.5 transition-transform cursor-pointer hover:border-accent"
                    >
                        <template #figure>
                            <div class="w-full h-full flex justify-center pt-16 pb-8">
                                <HeroIcon :name="`${icon}Icon`" />
                            </div>
                        </template>
                        <h2 class="card-title">
                            {{ title }}
                        </h2>
                        <p>{{ text }}</p>
                    </PageCard>
                </NuxtLink>
            </div>
            <NuxtLink
                :to="useText('ctaLink')"
                class="btn btn-primary btn-lg text-lg lg:text-3xl px-10 text-base-100 shadow-xl animate-bounce"
            >
                {{ useText('cta') }}
            </NuxtLink>
        </PageSection>
        <PageSection id="about-me" class="!py-20">
            <div class="flex flex-col lg:flex-row gap-10 justify-center items-center border-b-[1px]">
                <div class="max-w-[400px] max-h-full">
                    <img
                        src="/klara_homepage.png"
                        alt="Klára"
                        class="w-full h-full object-cover"
                        loading="lazy"
                        width="800"
                        height="1042"
                    />
                </div>
                <div class="w-full lg:w-1/2">
                    <div class="prose">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div v-html="useText('medailonek')"></div>
                    </div>
                </div>
            </div>
        </PageSection>
        <PageSection id="catalog-references" class="!py-0">
            <CatalogReferenceCarousel />
        </PageSection>
    </div>
</template>

<style lang="postcss">
.about-content {
    p {
        @apply text-xl leading-[2em];
    }

    h3 {
        @apply text-2xl md:text-3xl my-8;
    }
}
</style>
