<script setup lang="ts">

</script>

<template>
    <div
        class="card w-full lg:w-96 bg-base-100 shadow-xl border-primary border-dashed border-2"
    >
        <figure>
            <slot name="figure" />
        </figure>
        <div class="card-body">
            <slot />
        </div>
    </div>
</template>

<style scoped lang="postcss">

</style>
