<script setup lang="ts">
import type { CatalogReference } from '@prisma/client';
import CatalogReferenceItem from '~/components/ui/CatalogReferenceItem.vue';
import DaisyCarousel from '~/components/ui/DaisyCarousel.vue';
import { useTexts } from '~/utils/client/useTexts';

const { data: references } = useFetch<CatalogReference[]>(
    '/api/catalog-references',
);
const useText = await useTexts();
</script>

<template>
    <div
        v-if="references?.length ?? 0 > 0"
        class="max-w-full py-20"
    >
        <h2
            class="text-accent relative -left-2 cursor-default text-2xl md:text-4xl lg:text-6xl z-20 animate-on-scroll move-left mb-10 lg:mb-20"
        >
            {{ useText('reference-nadpis') }}
        </h2>
        <DaisyCarousel :items="references ?? []">
            <template #default="{ item }">
                <NuxtLink
                    :key="item.id"
                    :to="item.url ?? undefined"
                >
                    <CatalogReferenceItem
                        :image="item.image"
                        :name="item.name"
                        :message="item.message"
                        :url="item.url"
                    />
                </NuxtLink>
            </template>
        </DaisyCarousel>
    </div>
</template>
